<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tabla Dinamica Rutas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Empresas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div class="card">
          <div class="card-header pt-2 pb-2">
            <div class="row">
              <div class="col-md-6">
                <h5>Filtros</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- Acordeón de filtros -->
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label>Seleccionar Tipo Busqueda</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="tipo_consulta"
                    :class="
                      $v.tipo_consulta.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option :value="1">Rutas</option>
                    <option :value="2">Sitios y Puntos de Gestión</option>
                  </select>
                </div>
              </div>
              <div class="form-group col-md-2">
                <label>Nombre Ruta</label>
                <input
                  type="text"
                  id="nombre1"
                  v-model="filtros.nombre"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Origen</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="origen"
                  placeholder="Origenes"
                  label="nombre"
                  class="form-control form-control-sm p-0 col-md-10"
                  :options="listasForms.origenes"
                  :filterable="true"
                  @input="getSelectOrigenes()"
                ></v-select>
              </div>
              <div class="form-group col-md-2">
                <label>Destino</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="destino"
                  placeholder="Destinos"
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.destinos"
                  :filterable="true"
                  @input="getSelectDestinos()"
                ></v-select>
              </div>
              <div class="form-group col-md-2">
                <label>Despachado A</label>
                <select
                  class="form-control form-control-sm p-0"
                  v-model="filtros.despachado_a_id"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="despachado_a_id in listasForms.despachados_a"
                    :key="despachado_a_id.numeracion"
                    :value="despachado_a_id.numeracion"
                  >
                    {{ despachado_a_id.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>Tipo de Ruta</label>
                <select
                  class="form-control form-control-sm p-0"
                  v-model="filtros.tipo_ruta"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_ruta in listasForms.tipo_rutas"
                    :key="tipo_ruta.numeracion"
                    :value="tipo_ruta.numeracion"
                  >
                    {{ tipo_ruta.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>Horas de Vigencia</label>
                <input
                  type="number"
                  id="horas_vigencia"
                  v-model="filtros.horas_vigencia"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="col-md-1">
                <button
                  type="button"
                  v-show="!$v.tipo_consulta.$invalid"
                  class="btn bg-navy btn-sm"
                  @click="generarPivotTable()"
                  v-if="$store.getters.can('admin.rutas.pivotTable')"
                >
                  Generar Busqueda
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <pivot-table-component
                      v-if="pivotData"
                      :pivotData="pivotData"
                      :aggregator-name="aggregatorName"
                      :renderer-name="rendererName"
                    >
                    </pivot-table-component>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i>
                  <br />Volver
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PivotTableComponent from "../../../../components/PivotTableComponent.vue";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
export default {
  name: "EmpresaPivotTable",
  components: {
    PivotTableComponent,
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      dataPivot: [],
      pivotData: [],
      aggregatorName: "Sum",
      rendererName: "Table Heatmap",
      filtros: {
        nombre: null,
        origen_id: null,
        destino_id: null,
        despachado_a_id: null,
        tipo_ruta: null,
        horas_vigencia: null,
      },
      origen: {},
      destino: {},
      tipo_consulta: null,
      listasForms: {
        origenes: [],
        destinos: [],
        despachados_a: [],
        tipo_rutas: [],
      },
    };
  },
  validations: {
    tipo_consulta: {
      required,
    },
  },
  methods: {
    back() {
      return this.$router.replace("/Admin/Rutas");
    },
    async getOrigenes() {
      await axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.origenes = response.data;
      });
    },
    getSelectOrigenes() {
      this.filtros.origen = {};
      this.filtros.origen_id = null;
      if (this.origen) {
        this.filtros.origen = this.origen;
        this.filtros.origen_id = this.origen.id;
      }
    },
    async getDestinos() {
      await axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.destinos = response.data;
      });
    },
    getSelectDestinos() {
      this.filtros.destino = {};
      this.filtros.destino_id = null;
      if (this.destino) {
        this.filtros.destino = this.destino;
        this.filtros.destino_id = this.destino.id;
      }
    },

    getDespachados() {
      axios.get("/api/lista/54").then((response) => {
        this.listasForms.despachados_a = response.data;
      });
    },

    getTipoRutas() {
      axios.get("/api/lista/48").then((response) => {
        this.listasForms.tipo_rutas = response.data;
      });
    },
    generarPivotTable() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/rutas/pivotTable",
        data: { tipo_consulta: this.tipo_consulta, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          this.pivotData = response.data;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getOrigenes();
    await this.getDestinos();
    await this.getDespachados();
    await this.getTipoRutas();
    this.cargando = false;
  },
};
</script>
